import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, AfterViewInit {
  toggled = true;
  is_open = false;
  @ViewChild('mm')
  mm!: ElementRef;

  navigations = [
    {
      url: '/faq',
      name: 'أسئلة و أجوبة',
    },
    {
      url: '/previous',
      name: 'النسخة السابقة',
    },
    {
      //url: '/application',
      url: '/#',
      name: 'إيداع ملف الترشيح',
    },
    {
      url: '/#conditions',
      name: 'شروط الترشيح',
    },
  ];
  constructor() {}
  ngOnInit(): void {}
  ngAfterViewInit(): void {}

  toggleMenu() {
    this.toggled = !this.toggled;
    const cc: HTMLDivElement = this.mm.nativeElement;
    cc.classList.add('chtl-expaned');
  }
  closeModal() {
    this.is_open = false;
  }
}
